import { gql } from '@apollo/client'
import produce from 'immer'

import { Filter } from '../../../types/filters'
import type { SelectTitleOption } from '../../../types/ui'
import { User } from '../../models/user'

const numericOperators: Filters.NumericOperator[] = [
  '=',
  '!=',
  '>',
  '>=',
  '<',
  '<='
]
const percentageOperators: Filters.PercentageOperator[] = [
  '=',
  '!=',
  '>',
  '>=',
  '<',
  '<='
]
export const stringOperators: Filters.StringOperator[] = [
  'contains',
  'does not contain',
  'is exactly',
  'similar to',
  'starts with',
  'does not start with',
  'ends with',
  'does not end with',
  'is empty',
  'is not empty'
]

export const listOperators: Filters.ListOperator[] = [
  'is',
  'is not',
  'is empty',
  'is not empty'
]

const booleanOperators: Filters.BooleanOperator[] = ['is']

export const groupOperators: Filters.GroupOperator[] = [
  'has any of',
  'has none of',
  'has all of'
]

export const errorOperators: Filters.ErrorOperator[] = [
  'matches any of',
  'matches none of'
]

const flowOperators: Filters.FlowOperator[] = [
  'has received',
  'has not received',
  'has received any of',
  'has not received any of',
  'has responded to any of',
  'has responded to none of',
  'is active in any of',
  'is not active in any of'
]

const broadcastOperators: Filters.BroadcastOperator[] = [
  'has received',
  'has not received',
  'has received any of',
  'has responded to any of',
  'has responded to none of'
]

const linkClickOperators: Filters.LinkClickOperator[] = [
  'has clicked',
  'has not clicked',
  'has clicked containing',
  'has not clicked containing'
]

const linkOperators: Filters.LinkOperator[] = [
  'has received',
  'has not received',
  'has received containing',
  'has not received containing'
]

const timezoneOperators: Filters.TimezoneOperator[] = [
  'is in',
  'is not in',
  'is empty',
  'is not empty'
]

const dateOperators: Filters.DateOperator[] = [
  'on',
  'before',
  'after',
  'in the last',
  'in the next',
  'days ago'
]
const areaOperators: Filters.AreaOperator[] = [
  'within radius',
  'is',
  'is empty',
  'is not empty'
]

const callTargetOperators: Filters.CallTargetOperator[] = [
  'has called any of',
  'has not called any of'
]

const senatorOperators: Filters.SenatorOperator[] = ['by party', 'by senator']

const representativeOperators: Filters.RepresentativeOperator[] = [
  'by party',
  'by representative',
  'by district'
]

export type FilterData = {
  broadcasts: any
  flowData: any
  tzData: any
  targets: any
  automatedMemberData: any
  organizerData: any
  campaignId: string | number
}

const assignmentOperators: Filters.AssignmentOperator[] = ['is', 'is not']

const accessGroupPermissionOperators = ['contains', 'does not contain']

const permissionOperator = [
  'has permission to view',
  'has permission to view/edit',
  'does not have permission to view',
  'does not have permission to view/edit'
]

export const coreFields: Filters.FilterField[] = [
  { title: 'Phone Number', type: 'string', id: 'phoneNumber' },
  { title: 'Last Name', type: 'string', id: 'lastName' },
  { title: 'First Name', type: 'string', id: 'firstName' },
  { title: 'Subscribed', type: 'boolean', id: 'isSubscribed' },
  { title: 'Source', type: 'string', id: 'first_subscription_event' },
  { title: 'Email', type: 'string', id: 'email' },
  { title: 'Street Address', type: 'string', id: 'streetAddress' },
  { title: 'Zipcode', type: 'area', id: 'zipcode' },
  { title: 'City', type: 'string', id: 'city' },
  { title: 'State', type: 'string', id: 'state' },
  { title: 'Timezone', type: 'timezone', id: 'timezone' },
  { title: 'Groups', type: 'group', id: 'groups' },
  {
    title: 'Broadcasts',
    type: 'broadcast',
    id: 'broadcast',
    advanced: {
      dateDescription: 'Messages Sent',
      includeBroadcasts: false,
      includeFlows: false,
      excludeOptions: ['has responded to any of', 'has responded to none of']
    }
  },
  {
    title: 'Flows',
    type: 'flow',
    id: 'flow',
    advanced: {
      dateDescription: 'Messages Received',
      includeBroadcasts: false,
      includeFlows: false,
      excludeOptions: ['is active in any of', 'is not active in any of']
    }
  },
  {
    title: 'Response Rate',
    type: 'percentage',
    id: 'responseRate',
    advanced: {
      dateDescription: 'Messages Received',
      includeBroadcasts: true,
      includeFlows: true
    }
  },
  {
    title: 'Click Rate',
    type: 'percentage',
    id: 'clickThroughRate',
    advanced: {
      dateDescription: 'Links Sent',
      includeBroadcasts: true,
      includeFlows: true
    }
  },
  {
    title: 'Call Rate',
    type: 'percentage',
    id: 'callRate',
    advanced: {
      dateDescription: 'Calls Made',
      includeBroadcasts: true,
      includeFlows: true
    }
  },
  {
    title: 'Senators',
    type: 'federalSenator',
    id: 'federalSenator'
  },
  {
    title: 'Representative',
    type: 'federalRepresentative',
    id: 'federalRepresentative'
  },
  {
    title: 'Call Target',
    type: 'callTarget',
    id: 'callTarget',
    advanced: {
      dateDescription: 'Calls Made',
      includeBroadcasts: true,
      includeFlows: true
    }
  },
  {
    title: 'Link Sent',
    type: 'linkOperator',
    id: 'linkOperator',
    advanced: {
      dateDescription: 'Links Sent',
      includeBroadcasts: true,
      includeFlows: true
    }
  },
  {
    title: 'Link Clicked',
    type: 'linkClicked',
    id: 'linkClicked',
    advanced: {
      dateDescription: 'Links Sent',
      includeBroadcasts: true,
      includeFlows: true
    }
  },
  {
    title: 'Sentiment',
    type: 'percentage',
    id: 'sentimentAvg',
    advanced: {
      dateDescription: 'Texts received',
      includeBroadcasts: true,
      includeFlows: true
    }
  },
  { title: 'Starred by Me', type: 'boolean', id: 'starredByCurrentUser' },
  {
    title: 'Last Message Direction',
    type: 'messageDirection',
    id: 'lastMessageDirection'
  },
  { title: 'Date Joined', type: 'date', id: 'createdAt' },
  { title: 'Carrier', type: 'string', id: 'mobileOperatorName' },
  {
    title: 'Msg Error Codes',
    type: 'error',
    id: 'errorCode',
    advanced: {
      dateDescription: 'Error occurred',
      includeBroadcasts: true,
      includeFlows: true
    }
  },
  { title: 'Assignment', type: 'assignment', id: 'organizerId' }
]

export const operators = {
  numeric: numericOperators.map((x) => ({ id: x, title: x })),
  number: numericOperators.map((x) => ({ id: x, title: x })),
  percentage: percentageOperators.map((x) => ({ id: x, title: x })),
  string: stringOperators.map((x) => ({ id: x, title: x })),
  text: stringOperators.map((x) => ({ id: x, title: x })),
  select: listOperators.map((x) => ({ id: x, title: x })),
  multiselect: groupOperators.map((x) => ({ id: x, title: x })),
  currency: numericOperators.map((x) => ({ id: x, title: x })),
  group: groupOperators.map((x) => ({ id: x, title: x })),
  error: errorOperators.map((x) => ({ id: x, title: x })),
  boolean: booleanOperators.map((x) => ({ id: x, title: x })),
  date: dateOperators.map((x) => ({ id: x, title: x })),
  area: areaOperators.map((x) => ({ id: x, title: x })),
  broadcast: broadcastOperators.map((x) => ({ id: x, title: x })),
  flow: flowOperators.map((x) => ({ id: x, title: x })),
  timezone: timezoneOperators.map((x) => ({ id: x, title: x })),
  assignment: assignmentOperators.map((x) => ({ id: x, title: x })),
  messageDirection: booleanOperators.map((x) => ({ id: x, title: x })),
  callTarget: callTargetOperators.map((x) => ({ id: x, title: x })),
  federalSenator: senatorOperators.map((x) => ({ id: x, title: x })),
  federalRepresentative: representativeOperators.map((x) => ({
    id: x,
    title: x
  })),
  linkOperator: linkOperators.map((x) => ({ id: x, title: x })),
  linkClicked: linkClickOperators.map((x) => ({ id: x, title: x })),
  access_group: accessGroupPermissionOperators.map((x) => ({
    id: x,
    title: x
  })),
  group_permission: permissionOperator.map((x) => ({ id: x, title: x })),
  assignment_permission: permissionOperator.map((x) => ({ id: x, title: x })),
  field_permission: permissionOperator.map((x) => ({ id: x, title: x }))
}

// Add or replace a filter with new values.  Returns a new object.
export const replaceFilter = produce((filters: Filter[], newFilter: Filter) => {
  const found = filters.findIndex((value) => value.field == newFilter.field)
  if (found < 0) filters.push(newFilter)
  else filters[found] = newFilter
})

export const filtersReducer = produce(
  (state, { type, index, value, fields, key, field, payload }) => {
    if (type === 'reset') {
      return payload
    }
    if (type === 'toggleActive') {
      state[index].excluded = !state[index].excluded
      return
    }
    if (type === 'removeAdvanced') {
      state.forEach((next) => (next.advanced = null))
      return
    }

    // TODO since we only debounce inputs where you can type,
    if (type !== 'add' && typeof state[index] === 'undefined') return

    switch (type) {
      case 'add': {
        const field = fields[0]
        state.push({
          field: field.id,
          operator: operators[field.type][0].id
        })
        break
      }
      case 'remove': {
        state.splice(index, 1)
        break
      }
      case 'toggleAdvanced': {
        if (!state[index].advanced) {
          state[index].advanced = {
            startDate: null,
            endDate: null,
            flows: [],
            broadcasts: []
          }
        } else {
          state[index].advanced = null
        }
        break
      }
      case 'setAdvanced': {
        if (!state[index].advanced) {
          state[index].advanced = {
            startDate: null,
            endDate: null,
            daysAgo: null,
            last: null,
            flows: [],
            broadcasts: []
          }
        }
        if (key === 'daysAgo') {
          state[index].advanced.startDate = null
          state[index].advanced.endDate = null
          state[index].advanced.last = null
        }
        if (key === 'last') {
          state[index].advanced.startDate = null
          state[index].advanced.endDate = null
          state[index].advanced.daysAgo = null
        }
        if (key === 'startDate' || key === 'endDate') {
          state[index].advanced.last = null
          state[index].advanced.daysAgo = null
        }

        state[index].advanced[key] = value

        break
      }
      case 'setField': {
        const field = fields.find(({ id }) => id === value)

        state[index].field = value
        state[index].value = null
        state[index].advanced = null
        let hasOperator = !state[index].operator
        let operatorValidForType =
          hasOperator &&
          (operators[field.type] as any).find(
            ({ id }) => id === state[index].operator
          )

        if (!hasOperator || !operatorValidForType) {
          state[index].operator = operators[field.type][0].id
        }
        if (field.type === 'field_permission') {
          state[index].value = field.type
        }
        if (field.type === 'assignment_permission') {
          state[index].value = field.type
        }
        if (field.type === 'boolean') {
          state[index].value = 'true'
        }
        if (field.type === 'messageDirection') {
          state[index].value = 'incoming'
        }
        if (field.type === 'select') {
          const firstOption = field?.options?.[0] || {}
          state[index].value = firstOption.value
        }
        if (field.type === 'multiselect') {
          state[index].value = []
        }
        if (field.type === 'assignment') {
          const firstOption = field?.options?.[0] || {}
          state[index].value = firstOption.value
        }
        break
      }
      case 'setOperator':
        if (
          doesOperatorChangeValueType(
            ['in the ', 'days ago'],
            state[index].operator,
            value
          )
        ) {
          state[index].value = null
        }

        state[index].operator = value
        if (['is empty', 'is not empty'].includes(value)) {
          state[index].value = 'true'
        }
        if (field?.advanced?.excludeOptions?.includes(value)) {
          state[index].advanced = null
        }
        break
      case 'setValue':
        state[index].value = value
        break
    }
  }
)

// Helper function to determine if the operator change requires setting the value to null
const doesOperatorChangeValueType = (
  operators: string[],
  operatorValue: any,
  value: any
) => {
  return (operators ?? []).reduce((valueHasChanged, operator) => {
    if (valueHasChanged) return valueHasChanged
    return (
      (operatorValue?.startsWith(operator) && !value.startsWith(operator)) ||
      (value.startsWith(operator) && !operatorValue?.startsWith(operator))
    )
  }, false)
}

export const filtersFromJson = (json: any) => {
  if (!json) {
    return {
      combiner: 'and',
      filters: []
    }
  }

  return {
    combiner: json[0],
    filters: json[1].map(([field, operator, value, advanced]) => ({
      field,
      operator,
      value: typeof value === 'boolean' ? JSON.stringify(value) : value,
      advanced
    }))
  }
}

export const filtersToJson = (
  filters: Filter[],
  combiner: Filters.Combiner
) => {
  filters = filters.filter(
    ({ field, operator, value, excluded }) =>
      field != null &&
      operator != null &&
      value != null &&
      !excluded &&
      (!Array.isArray(value) || value.length > 0)
  )
  if (!filters.length) return

  return [
    combiner,
    filters
      .map(({ field, operator, value, advanced }: any) => {
        const isCustom = field.startsWith('custom.')
        if (!field || !operator || (!value && value !== 0)) return
        if (field === 'organizerId' && ['is', 'is not'].includes(operator)) {
          let val = typeof value === 'object' ? value[0] : value
          if (val === 0) {
            val = null
          }
          return [field, operator, val]
        }
        if (
          field === 'zipcode' &&
          ['is empty', 'is not empty'].includes(operator)
        ) {
          return [field, operator]
        }
        if (field === 'zipcode' && ['is', 'is not'].includes(operator)) {
          const val = typeof value === 'object' ? value[0] : value
          return [field, operator, val]
        }
        if (
          field === 'zipcode' &&
          (value?.length !== 2 || !value?.[0] || !value?.[1])
        )
          return
        if (
          !isCustom &&
          operator === 'is' &&
          !['lastMessageDirection', 'linkClicked'].includes(field)
        ) {
          value = value === 'true'
        }
        return [field, operator, value, advanced]
      })
      .filter((x) => x)
  ]
}

export const getSenatorItemsGivenOpertor = (
  filter: Filter,
  data: {
    senators: SelectTitleOption[]
    parties: SelectTitleOption[]
  }
) => {
  if (filter.operator === 'by party') {
    return data?.parties || []
  }
  return data?.senators || []
}

export const getRepresentativeItemsGivenOpertor = (
  filter: Filter,
  data: {
    representatives: SelectTitleOption[]
    parties: SelectTitleOption[]
    districts: SelectTitleOption[]
  }
) => {
  if (filter.operator === 'by party') {
    return data?.parties || []
  } else if (filter.operator === 'by district') {
    return data?.districts || []
  }
  return data?.representatives || []
}

export const getMembersUrlWithFilters = (campaignId, filters) => {
  if (!filters) return `/campaigns/${campaignId}/members`
  const filter = encodeURIComponent(
    JSON.stringify(filtersToJson(filters, 'and'))
  )
  return `/campaigns/${campaignId}/members?filters=${filter}`
}

export const getMembersUrlWithFiltersAddingFilter = (
  campaignId,
  filters,
  addFilter: Filter
) => {
  if (!filters) filters = []
  let newFilters = replaceFilter(filters, addFilter)
  return getMembersUrlWithFilters(campaignId, newFilters)
}

export const GET_ERROR_CODE_VALUES_QUERY = gql`
  query providerStatusCodes {
    providerStatusCodes(orderBy: STATUS_CODE_ASC) {
      nodes {
        id: statusCode
      }
    }
  }
`

export const formatUserData = (userData: Array<User>) => {
  if (userData) {
    let formattedUserData = userData
      .filter((user) => user.archived_at === null)
      .sort((a, b) => Number(b.isAdmin) - Number(a.isAdmin))
      .map((user) => {
        return {
          id: user.id,
          title: `${user.isAdmin ? 'Admin' : 'Organizer'}, ${user.firstName} ${
            user.lastName
          }`
        }
      })
    formattedUserData.unshift({ id: 0, title: 'Unassigned' })
    return formattedUserData
  }
}

export const formatStatusCodes = (data: any) => {
  let formatCodes = data?.providerStatusCodes?.nodes || []
  const seen = []
  formatCodes = formatCodes.filter((obj) => {
    if (!seen.includes(obj.id)) {
      seen.push(obj.id)
      return true
    }
    return false
  })
  formatCodes = formatCodes.map((next) => {
    return { title: `${next.id}`, id: next.id }
  })
  return formatCodes
}

export function filterValueForDate(operator, value): string {
  if (!operator) return ''
  const dateType = filterOperatorTypeForDate(operator)

  if (dateType === 'daysOnly' || dateType === 'interval') return value

  if (value) return new Date(value).toLocaleDateString()
  return null
}

export function filterOperatorTypeForDate(operator) {
  if (operator === 'days ago') {
    return 'daysOnly'
  } else if (operator.startsWith('in the')) {
    return 'interval'
  }
  return 'date'
}

export function filterOperatorDisplay(operator) {
  if (operator === 'days ago') {
    return 'ago'
  }
  return operator
}

export const defaultAutomatedMemberFields = {
  districts: [],
  parties: [],
  representatives: [],
  senators: []
}
